import React, { useState } from "react";
import Model from "@tripian/model";
import EventCard from "../EventCard/EventCard";
import PlaceDetailsModal from "../PlaceDetailsModal/PlaceDetailsModal";

type Props = {
  events: Model.CityEvent[];
};

const EventList: React.FC<Props> = ({ events }) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<Model.CityEvent | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEventSelect = (index: number) => {
    if (selectedIndex === index) {
      setSelectedIndex(null);
      return;
    }

    setSelectedIndex(index);
  };

  const handleGetTickets = (event: Model.CityEvent) => {
    if (event.ticketInfo && event.ticketInfo.length > 0) {
      window.open(event.ticketInfo[0].link, "_blank");
    }
  };

  return (
    <div className="space-y-4">
      {events.map((event, index) => (
        <div
          key={event.id}
          onClick={() => handleEventSelect(index)}
          className={`cursor-pointer transition-all duration-300 ${selectedIndex === index ? "transform scale-[1.02]" : "hover:scale-[1.01]"}`}
        >
          <EventCard
            title={event.title}
            image={event.image}
            date={event.date}
            address={event.address}
            venue={event.venue}
            expanded={selectedIndex === index}
            onGetTickets={() => handleGetTickets(event)}
            onMoreInfo={() => {
              setSelectedEvent(event);
              setIsModalOpen(true);
            }}
          />
        </div>
      ))}

      {selectedEvent && <PlaceDetailsModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} event={selectedEvent} />}
    </div>
  );
};

export default EventList;
