/* eslint-disable @typescript-eslint/brace-style */
/* eslint-disable no-console */
/* eslint-disable import/no-extraneous-dependencies */
import { Providers } from '@tripian/model';
import { AxiosError } from 'axios';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const handleHttpResponseErrorForViator = (errorResponse: AxiosError<Providers.Viator.Error | Providers.Viator.OriginalErrorResponse>) => {
  let result: any = '';
  // Has Response
  if (errorResponse.response) {
    // Has Response Data
    if (errorResponse.response.data) {
      const { data } = errorResponse.response;

      // Handle Viator API error with originalResponse
      if (((data as Providers.Viator.Error).originalResponse as Providers.Viator.OriginalErrorResponse)?.message) {
        result = (data as Providers.Viator.Error).originalResponse.message;
      }
      // Handle Viator API error with "code" and "message"
      else if ((data as Providers.Viator.OriginalErrorResponse).code && (data as Providers.Viator.OriginalErrorResponse).message) {
        result = (data as Providers.Viator.OriginalErrorResponse).message;
      }
      // Fallback to generic error
      else if ((data as Providers.Viator.Error).error) {
        result = (data as Providers.Viator.Error).error;
      } else {
        result = 'Unknown error occurred in response data.';
      }
    } else {
      result = 'Unknown error occurred. No response data.';
    }
  }
  // No Response, but has HTTP error message
  else if (errorResponse.message) {
    console.log('Has Not Response. Has HTTP error message');
    result = errorResponse.message;
  }
  // Completely unknown error
  else {
    console.log('Has Not Response. Has not HTTP error message');
    result = 'An unknown error occurred.';
  }

  console.error('Processed error response:', result);
  throw JSON.stringify(result);
};

// eslint-disable-next-line import/prefer-default-export
export { handleHttpResponseErrorForViator };
