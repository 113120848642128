import { AxiosError } from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import Model from '@tripian/model';

import data from '../../data/data';
import cache from './cache/cache';

/**
 * All successfully (axios response status code) resolved promises firstly handles by this function. easy.log calling.
 * If dataKey exist and httpResponse.data.success is true, httpResponse.data.data is saving  to tripian.data[dataKey]
 * @param {AxiosResponse} httpResponse axios response object
 * @param {string} dataKey
 * @returns {any} httpResponse.data axios httpResponse.data
 */
const handleHttpResponseSuccess = <T>(
  httpResponse: Model.SuccessResponse<T>,
  dataKey: string,
  lang: Model.LangCodeKey,
  params?: any,
  url?: string,
): Model.DataPayload<T> => {
  // easy.log(JSON.parse(`${dataKey} dataKey saving..${httpResponse}`));
  if (httpResponse.success) {
    // TODO dont cache is useCache === false
    cache<T>(httpResponse.data, dataKey, lang, params, url, httpResponse.pagination);
  } else {
    throw httpResponse.message;
  }

  return { data: httpResponse.data, pagination: httpResponse.pagination };
};

/**
 * All failed (axios response status code) promises firstly handles by this function.
 * setting tripian.data.hasError to true
 *  easy.log calling.
 * If dataKey exist (and dataKey !== 'trip') and httpResponse.data.success is true, httpResponse.data.data is saving  to tripian.data[dataKey]
 * @param {AxiosError} errorResponse is axios error response object
 * @param {string} dataKey
 * @returns {AxiosError} errorResponse is axios error response object
 */
const handleHttpResponseError = (errorResponse: AxiosError<Model.ErrorResponse>, dataKey: string, params?: any) => {
  data.hasError = true;
  data.errors.push({ dataKey, errorResponse, params });
  if (dataKey) {
    if (dataKey !== 'trip') {
      (data as any)[dataKey] = null;
    }
  }

  // TRIPIAN API error handle
  if (errorResponse.response) {
    if (errorResponse.response.data) {
      if (errorResponse.response.data.message) throw errorResponse.response.data.message;
      throw errorResponse.response.data;
    }
    throw errorResponse.response;

    // HTTP error handle
  } else if (errorResponse.message) {
    throw errorResponse.message;
  }

  throw errorResponse;
};

export { handleHttpResponseSuccess, handleHttpResponseError };
