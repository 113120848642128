/* eslint-disable import/no-extraneous-dependencies */
import Model, { helper } from '@tripian/model';
import API_CONST from '../../../api/const/APICONST';
import data from '../../../data/data';

class Cached {
  /**
   ******************************************************************************
   *
   * Cities
   *
   */
  static cities = (lang: Model.LangCodeKey): Model.City[] | undefined => {
    return data.cities?.[lang];
  };

  static city = (lang: Model.LangCodeKey, cityId: number): Model.City | undefined => {
    return data.cities?.[lang]?.find((c) => c.id === cityId);
  };

  static cityInfo = (lang: Model.LangCodeKey, cityId: number): Model.CityInfo | undefined => {
    return data.citiesInfo?.[lang]?.find((c) => c.id === cityId.toString());
  };

  static citiesEvents = (lang: Model.LangCodeKey, cityId: number): Model.CityEvent[] | undefined => {
    const eventsData = data.citiesEvents?.[lang]?.find((group) => group.cityId === cityId);
    return eventsData?.events;
  };

  /**
   ******************************************************************************
   *
   * Feedbacks
   *
   */
  static feedbacks = (lang: Model.LangCodeKey): Model.Feedbacks | undefined => {
    return data.feedbacks?.[lang];
  };

  static userFeedbacks = (lang: Model.LangCodeKey): Model.UserFeedback[] | undefined => {
    return data.userFeedbacks?.[lang];
  };

  /**
   ******************************************************************************
   *
   * POIS Categories
   *
   */
  static poiCategories = (lang: Model.LangCodeKey): Model.PoiCategory[] | undefined => {
    return data.poiCategories?.[lang];
  };

  /**
   ******************************************************************************
   *
   * POIS Category Tags
   *
   */

  static autoCompleteTagsAll = (lang: Model.LangCodeKey, cityId: number, poiCategories: string): { id: number; name: string }[] | undefined => {
    const categoryIds = poiCategories.split(',').map(Number);

    const tagsData = data.autoCompleteTagsAll?.[lang]?.find((pa) => pa.cityId === cityId && helper.arraysEqual(pa.poiCategories, categoryIds));

    if (tagsData) {
      return tagsData.tags;
    }

    return undefined;
  };

  /**
   ******************************************************************************
   *
   * POI
   *
   */ static poi = (lang: Model.LangCodeKey, poiId: string): Model.Poi | undefined => {
    const langPois = data.pois?.[lang];
    if (!langPois) return undefined;

    // eslint-disable-next-line no-restricted-syntax
    for (const group of langPois) {
      const foundPoi = group.pois.find((p) => p.id === poiId);
      if (foundPoi) {
        return foundPoi;
      }
    }
    return undefined;
  };

  static pois = (lang: Model.LangCodeKey, showOffersOnly: number, poiIds: string[]): Model.Poi[] | undefined => {
    if (poiIds.length === 0) return undefined;
    const filterPois = data.pois?.[lang]?.find((group) => group.showOffersOnly === showOffersOnly)?.pois.filter((p) => poiIds.includes(p.id));
    if (filterPois?.length === poiIds.length) return filterPois;
    return undefined;
  };

  static poisAll = (
    lang: Model.LangCodeKey,
    cityId: number,
    poiCategories: string,
    showOffersOnly: number,
  ): Model.DataPayload<Model.Poi[]> | undefined => {
    const categoryIds = poiCategories.split(',').map(Number);
    const poisData = data.poisAll?.[lang]?.find(
      (group) => group.cityId === cityId && helper.arraysEqual(group.poiCategories, categoryIds) && group.showOffersOnly === showOffersOnly,
    );

    if (poisData) {
      return poisData.pois;
    }

    return undefined;
  };

  /**
   ******************************************************************************
   *
   * User
   *
   */
  static user = (): Model.User | undefined => {
    return data.user;
  };

  /**
   ******************************************************************************
   *
   * Favorites
   *
   */
  static favorites = (lang: Model.LangCodeKey, cityId = data.trip?.[lang]?.city?.id): Model.Favorite[] | undefined => {
    return data.favorites?.[lang]?.find((favorite) => favorite.cityId === cityId)?.favoritePois;
  };

  /**
   ******************************************************************************
   *
   * Companions
   *
   */
  static companions = (lang: Model.LangCodeKey): Model.Companion[] | undefined => {
    return data.companions?.[lang];
  };

  /**
   ******************************************************************************
   *
   * Notifications
   *
   */
  // static notifications = (): Model.Notification[] | undefined => {
  //  return data.notifications;
  // };

  /**
   ******************************************************************************
   *
   * Reservations
   *
   */
  static reservations = (lang: Model.LangCodeKey): Model.UserReservation[] | undefined => {
    return data.reservations?.[lang];
  };

  /**
   ******************************************************************************
   *
   * User-Reactions
   *
   */
  static userReactions = (lang: Model.LangCodeKey, tripHash?: string): Model.UserReaction[] | undefined => {
    const currentTripHash = tripHash || data.trip?.[lang]?.tripHash;
    if (currentTripHash) return data.userReactions?.[lang]?.find((reactionData) => reactionData.tripHash === currentTripHash)?.userReactions;
    return undefined;
  };

  /**
   ******************************************************************************
   *
   * Questions
   *
   */
  static questions = (lang: Model.LangCodeKey, qDataKey: string): Model.Question[] | undefined => {
    let questionDatas: Model.Question[] = [];
    switch (qDataKey) {
      case `${API_CONST.QUESTIONS.DATA_KEY}-${Model.QUESTIONS_CATEGORY.TRIP}`:
        questionDatas = data.questionsTrip?.[lang] || [];
        break;
      case `${API_CONST.QUESTIONS.DATA_KEY}-${Model.QUESTIONS_CATEGORY.PROFILE}`:
        questionDatas = data.questionsProfile?.[lang] || [];
        break;
      case `${API_CONST.QUESTIONS.DATA_KEY}-${Model.QUESTIONS_CATEGORY.COMPANION}`:
        questionDatas = data.questionsCompanion?.[lang] || [];
        break;
      default:
        break;
    }
    if (questionDatas.length === 0) return undefined;

    return questionDatas;
  };

  /**
   ******************************************************************************
   *
   * TripRefs
   *
   */
  static tripRefs = (): Model.TripReference[] | undefined => {
    return data.tripRefs;
  };

  static tripSavedRefs = (): Model.TripReference[] | undefined => {
    return data.tripSavedRefs;
  };

  static tripRef = (tripHash: string): Model.TripReference | undefined => {
    return data.tripRefs?.find((t) => t.tripHash === tripHash);
  };

  /**
   ******************************************************************************
   *
   * Trip
   *
   */
  static trip = (lang: Model.LangCodeKey, tripHash: string, minDayIndex: number): Model.Trip | undefined => {
    const cachedTrip = data.trips?.[lang]?.find((trip) => trip.tripHash === tripHash);
    if (cachedTrip) {
      const minPlanIndex = cachedTrip.plans.length - 1 < minDayIndex ? cachedTrip.plans.length - 1 : minDayIndex;
      if (cachedTrip.plans[minPlanIndex].generatedStatus !== 0) {
        const updatedTrip = helper.deepCopy(cachedTrip);
        data.trip = {
          ...data.trip,
          [lang]: updatedTrip,
        };
        return cachedTrip;
      }
    }
    // TODO
    // else find in data.trips ... not in tripRefs !!!
    return undefined;
  };

  static plan = (lang: Model.LangCodeKey, planId: number): Model.Plan | undefined => {
    return data.trip?.[lang]?.plans.find((p) => p.id === planId);
  };

  static topTen = (lang: Model.LangCodeKey, cityId: number): Model.TopTen[] | undefined => {
    return data.topTens?.[lang]?.find((tt) => tt.cityId === cityId)?.topTen;
  };

  /*   static step = (stepId: number): Model.StepDetail | undefined => {
    return data.plan?.steps?.find((s) => s.id === stepId);
  }; */
}

export default Cached;
