import React from "react";
import Model from "@tripian/model";
import { CalendarIcon, ClockIcon, LinkIcon, MapPinIcon, MusicIcon, StarIcon, XIcon } from "../../icons/Icons";
import { ImgLazy, Modal } from "@tripian/react";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  event: Model.CityEvent;
};

const PlaceDetailsModal: React.FC<Props> = ({ isOpen, onClose, event }) => {
  if (!isOpen) return null;

  return (
    <Modal show={isOpen} backdropClick={onClose} className="min-w-[90%] md:min-w-0 shadow-none rounded-xl">
      {/* <div className="fixed inset-0 bg-black bg-opacity-50 z-[9999] flex items-center justify-center p-4"> */}
      <div className="bg-white rounded-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
        <div className="relative h-64">
          <ImgLazy src={event.image} objectFit="contain" alt={event.title} />
          <button onClick={onClose} className="absolute top-4 right-4 p-2 bg-white rounded-full shadow-lg hover:bg-gray-100 transition-colors">
            <XIcon className="w-5 h-5" />
          </button>
          <div className="absolute bottom-4 right-4 bg-white px-3 py-1.5 rounded-full shadow-lg flex items-center gap-1">
            <StarIcon className="w-4 h-4 text-yellow-400 fill-yellow-400" />
            <span className="font-medium">{event.venue.rating}</span>
            <span className="text-sm text-gray-500">({event.venue.reviews} reviews)</span>
          </div>
        </div>

        <div className="p-6 space-y-6">
          <div>
            <h2 className="text-2xl font-semibold mb-2">{event.title}</h2>
            <div className="flex flex-col gap-2 text-gray-600">
              <div className="flex items-center gap-2">
                <MapPinIcon className="w-4 h-4" />
                <span>{event.address}</span>
              </div>
              <div className="flex items-center gap-2">
                <CalendarIcon className="w-4 h-4" />
                <span>{event.date.when}</span>
              </div>
            </div>
          </div>

          {/* <div className="grid grid-cols-2 gap-4">
            <div className="p-4 bg-gray-50 rounded-lg">
              <div className="flex items-center gap-2 text-gray-600 mb-1">
                <ClockIcon className="w-4 h-4" />
                <span className="text-sm font-medium">Duration</span>
              </div>
              <p className="text-gray-900">{event.date.when}</p>
            </div>
            <div className="p-4 bg-gray-50 rounded-lg">
              <div className="flex items-center gap-2 text-gray-600 mb-1">
                <UsersIcon className="w-4 h-4" />
                <span className="text-sm font-medium">Recommended Group</span>
              </div>
              <p className="text-gray-900">2-6 people</p>
            </div>
          </div> */}

          <div className="grid grid-cols-2 gap-4">
            <div className="p-4 bg-gray-50 rounded-lg">
              <div className="flex items-center gap-2 text-gray-600 mb-1">
                <ClockIcon className="w-4 h-4" />
                <span className="text-sm font-medium">Start Time</span>
              </div>
              <p className="text-gray-900">{event.date.startDateTime.split(" ")[1]}</p>
            </div>
            <div className="p-4 bg-gray-50 rounded-lg">
              <div className="flex items-center gap-2 text-gray-600 mb-1">
                <MusicIcon className="w-4 h-4" />
                <span className="text-sm font-medium">Venue</span>
              </div>
              <p className="text-gray-900">{event.venue.name}</p>
            </div>
          </div>

          <div className="space-y-4">
            <h3 className="text-lg font-semibold">About</h3>
            <p className="text-gray-600 leading-relaxed">{event.description}</p>
          </div>

          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Get Tickets</h3>
            <div className="grid grid-cols-1 gap-3">
              {event.ticketInfo.map((ticket, index) => (
                <a
                  key={index}
                  href={ticket.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-between p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
                >
                  <div className="flex items-center gap-2">
                    <div className="w-4 mr-2">
                      <LinkIcon width="20" height="20" />
                    </div>
                    <span className="text-sm md:text-base">{ticket.source}</span>
                  </div>
                  <span className="text-primary-color">Buy Tickets</span>
                </a>
              ))}
            </div>
          </div>
          {event.venue.link && (
            <div className="pt-4">
              <a
                href={event.venue.link}
                target="_blank"
                rel="noopener noreferrer"
                className="w-full px-4 py-2 bg-primary-color text-white rounded-lg transition-colors flex items-center justify-center gap-2"
              >
                <MapPinIcon className="w-4 h-4" />
                View on Maps
              </a>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default PlaceDetailsModal;
