import React, { useCallback, useMemo, useState } from "react";
import { api } from "@tripian/core";
import useTranslate from "../../hooks/useTranslate";
import { Button, DestinationSelect, PreLoading } from "@tripian/react";
import AppNav from "../../App/AppNav/AppNav";
import { CITY_INFO } from "../../constants/ROUTER_PATH_TITLE";
import useCities from "../../hooks/useCities";
import Model, { helper } from "@tripian/model";
import { CalendarIcon, InfoIcon, MapPinIcon, PhoneIcon, PowerIcon, ThermometerIcon, WifiIcon } from "./icons/Icons";
import EventList from "./Events/EventList/EventList";
import classes from "./CityInfoPage.module.scss";

export type RSelectOption = {
  id: number;
  label: string;
  payload: { destinationId: number; destinationName: string; parentName: string };
  isSelected?: boolean;
};

const CityInfoPage = () => {
  const { cities, loadingCities } = useCities();
  const [selectedCityId, setSelectedCityId] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [info, setInfo] = useState<{ city: Model.City; details: Model.CityInfo; events: Model.CityEvent[] }>();
  const [error, setError] = useState<string>();
  const [showMore, setShowMore] = useState(false);

  const { t } = useTranslate();

  const toggleShowMore = () => {
    setShowMore((prev) => !prev);
  };

  const destinations: { destinationId: number; destinationName: string; parentName: string }[] = useMemo(() => {
    if (cities) {
      return cities?.map((city) => {
        const destination = {
          destinationId: city.id,
          destinationName: city.name,
          parentName: city.parentLocations.length === 0 ? city.country.name : `${city.parentLocations.map((parent) => parent.name).join(", ")}, ${city.country.name}`,
        };

        return destination;
      });
    }
    return [];
  }, [cities]);

  const destinationOptions: RSelectOption[] = useMemo(
    () =>
      destinations
        .sort((a, b) => helper.compareStringForSort(a.destinationName, b.destinationName))
        .map((d) => ({
          id: d.destinationId,
          label: `${d.destinationName} - ${d.parentName}`,
          payload: d,
          isSelected: d.destinationId === selectedCityId,
        })),
    [destinations, selectedCityId]
  );

  const handleCityInfoFetch = async () => {
    if (selectedCityId) {
      setLoading(true);
      try {
        const cityInfo = await api.cityInfo(selectedCityId);
        const cityEvents = await api.cityEvents(selectedCityId);
        const city = cities?.find((city) => city.id === selectedCityId);
        if (city) {
          const info = { city, details: cityInfo, events: cityEvents };
          setInfo(info);
          setError(undefined);
        }
        setLoading(false);
      } catch (error: any) {
        setInfo(undefined);
        setError(error);
        setLoading(false);
      }
    }
  };

  const handleCitySelection = (selectedOption: RSelectOption) => {
    setSelectedCityId(selectedOption.id);
  };

  const formatMonths = useCallback((months: string[]): string => {
    const allMonths = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const monthIndices = months
      .map((month) => allMonths.indexOf(month))
      .filter((index) => index !== -1)
      .sort((a, b) => a - b);

    const ranges: { start: number; end: number }[] = [];
    let start = monthIndices[0];
    let end = monthIndices[0];

    for (let i = 1; i < monthIndices.length; i++) {
      if (monthIndices[i] === end + 1) {
        end = monthIndices[i];
      } else {
        ranges.push({ start, end });
        start = monthIndices[i];
        end = monthIndices[i];
      }
    }
    ranges.push({ start, end });

    const formattedRanges = ranges.map((range) => {
      if (range.start === range.end) {
        return allMonths[range.start].slice(0, 3);
      }
      return `${allMonths[range.start].slice(0, 3)} - ${allMonths[range.end].slice(0, 3)}`;
    });

    return formattedRanges.join(", ");
  }, []);

  /* */
  /* */
  /* ********* CITY INFO ********* */

  const cityImgDiv = useMemo(() => {
    if (info) {
      return (
        <div className="relative h-[400px] rounded-2xl overflow-hidden mb-12">
          <img className="!w-full h-full object-cover" src={info.city ? helper.cityImgUrl(info.city.image.url || "", 800, 500) : ""} alt="" />
          <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent">
            <button className="absolute top-0 p-4 text-white/90 cursor-pointer focus:outline-none" onClick={() => setInfo(undefined)}>
              <svg fill="#fff" width="32px" height="32px" viewBox="0 0 24 24">
                <path d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64z" />
              </svg>
            </button>
            <div className="absolute bottom-0 p-8">
              <div className="flex items-center gap-2 text-white/90 mb-2">
                <MapPinIcon width="20" height="20" />
                <span className="text-lg">{info.city.country.name}</span>
              </div>
              <h1 className="text-5xl font-bold text-white mb-4">{info.city?.name}</h1>
              <p className="text-white/90 text-sm">{showMore ? info.city?.description : `${info.city?.description?.slice(0, 170)}...`} </p>
              <button onClick={toggleShowMore} className="text-white text-sm underline focus:outline-none">
                {showMore ? "Show Less" : "Show More"}
              </button>
            </div>
          </div>
        </div>
      );
    }
    return undefined;
  }, [info, showMore]);

  const cityInfoDetails = useMemo(() => {
    if (info) {
      const { wifiInformation, lifeQualityIndices, emergencyNumbers, powerInformation, bestTimeToVisit } = info.details.information;
      const cityEvents = info.events;

      return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {wifiInformation && (
            <div className="bg-white rounded-xl shadow-lg p-6 hover:shadow-xl transition-shadow">
              <div className="flex items-center gap-3 mb-4">
                <WifiIcon className="w-6 h-6 text-primary-color" />
                <h2 className="text-xl font-semibold text-gray-800">{t("cityInfo.wifiInformation.label")}</h2>
              </div>
              <ul className="space-y-3">
                <li className="flex justify-between">
                  <span className="text-gray-600">{t("cityInfo.wifiInformation.mobile")}</span>
                  <span className="font-medium">{wifiInformation.mobile}</span>
                </li>
                <li className="flex justify-between">
                  <span className="text-gray-600">{t("cityInfo.wifiInformation.broadband")}</span>
                  <span className="font-medium">{wifiInformation.broadband}</span>
                </li>
              </ul>
            </div>
          )}

          {lifeQualityIndices && (
            <div className="bg-white rounded-xl shadow-lg p-6 hover:shadow-xl transition-shadow">
              <div className="flex items-center gap-3 mb-4">
                <InfoIcon className="w-6 h-6 text-primary-color" />
                <h2 className="text-xl font-semibold text-gray-800">{t("cityInfo.lifeQualityIndices.label")}</h2>
              </div>
              <div className="space-y-3">
                <div className="flex items-center justify-between mb-2">
                  <span className="text-gray-600">{t("cityInfo.lifeQualityIndices.safety")}</span>
                  <div className="flex items-center gap-2">
                    <div className="w-24 h-2 rounded-full bg-gray-200">
                      <div className="h-full rounded-full bg-primary-color" style={{ width: `${lifeQualityIndices.safetyIndex.value}%` }}></div>
                    </div>
                    <span className="text-sm font-medium">{Math.round(lifeQualityIndices.safetyIndex.value)}%</span>
                  </div>
                </div>
                <div className="flex items-center justify-between mb-2">
                  <span className="text-gray-600">{t("cityInfo.lifeQualityIndices.healthCare")}</span>
                  <div className="flex items-center gap-2">
                    <div className="w-24 h-2 rounded-full bg-gray-200">
                      <div className="h-full rounded-full bg-primary-color" style={{ width: `${lifeQualityIndices.healthCareIndex.value}%` }}></div>
                    </div>
                    <span className="text-sm font-medium">{Math.round(lifeQualityIndices.healthCareIndex.value)}%</span>
                  </div>
                </div>
                <div className="flex items-center justify-between mb-2">
                  <span className="text-gray-600">{t("cityInfo.lifeQualityIndices.costOfLiving")}</span>
                  <div className="flex items-center gap-2">
                    <div className="w-24 h-2 rounded-full bg-gray-200">
                      <div className="h-full rounded-full bg-primary-color" style={{ width: `${lifeQualityIndices.costOfLivingIndex.value}%` }}></div>
                    </div>
                    <span className="text-sm font-medium">{Math.round(lifeQualityIndices.costOfLivingIndex.value)}%</span>
                  </div>
                </div>
                <div className="flex items-center justify-between mb-2">
                  <span className="text-gray-600">{t("cityInfo.lifeQualityIndices.climate")}</span>
                  <div className="flex items-center gap-2">
                    <div className="w-24 h-2 rounded-full bg-gray-200">
                      <div className="h-full rounded-full bg-primary-color" style={{ width: `${lifeQualityIndices.climateIndex.value}%` }}></div>
                    </div>
                    <span className="text-sm font-medium">{Math.round(lifeQualityIndices.climateIndex.value)}%</span>
                  </div>
                </div>
                <div className="flex items-center justify-between mb-2">
                  <span className="text-gray-600">{t("cityInfo.lifeQualityIndices.pollution")}</span>
                  <div className="flex items-center gap-2">
                    <div className="w-24 h-2 rounded-full bg-gray-200">
                      <div className="h-full rounded-full bg-primary-color" style={{ width: `${lifeQualityIndices.pollutionIndex.value}%` }}></div>
                    </div>
                    <span className="text-sm font-medium">{Math.round(lifeQualityIndices.pollutionIndex.value)}%</span>
                  </div>
                </div>
                <div className="flex items-center justify-between mb-2">
                  <span className="text-gray-600">{t("cityInfo.lifeQualityIndices.trafficCommuteTime")}</span>
                  <div className="flex items-center gap-2">
                    <div className="w-24 h-2 rounded-full bg-gray-200">
                      <div className="h-full rounded-full bg-primary-color" style={{ width: `${lifeQualityIndices.trafficCommuteTimeIndex.value}%` }}></div>
                    </div>
                    <span className="text-sm font-medium">{Math.round(lifeQualityIndices.trafficCommuteTimeIndex.value)}%</span>
                  </div>
                </div>
              </div>
            </div>
          )}

          {emergencyNumbers && (
            <div className="bg-white rounded-xl shadow-lg p-6 hover:shadow-xl transition-shadow">
              <div className="flex items-center gap-3 mb-4">
                <PhoneIcon className="w-6 h-6 text-primary-color" />
                <h2 className="text-xl font-semibold text-gray-800">{t("cityInfo.emergencyNumbers.label")}</h2>
              </div>
              <ul className="space-y-3">
                <li className="flex justify-between">
                  <span className="text-gray-600">{t("cityInfo.emergencyNumbers.police")}</span>
                  <span className="font-medium">{emergencyNumbers.police}</span>
                </li>
                <li className="flex justify-between">
                  <span className="text-gray-600">{t("cityInfo.emergencyNumbers.ambulance")}</span>
                  <span className="font-medium">{emergencyNumbers.ambulance}</span>
                </li>
                <li className="flex justify-between">
                  <span className="text-gray-600">{t("cityInfo.emergencyNumbers.fire")}</span>
                  <span className="font-medium">{emergencyNumbers.fire}</span>
                </li>
              </ul>
            </div>
          )}

          {powerInformation && (
            <div className="bg-white rounded-xl shadow-lg p-6 hover:shadow-xl transition-shadow">
              <div className="flex items-center gap-3 mb-4">
                <PowerIcon className="w-6 h-6 text-primary-color" />
                <h2 className="text-xl font-semibold text-gray-800">{t("cityInfo.powerInformation.label")}</h2>
              </div>
              <ul className="space-y-3">
                <li className="flex justify-between">
                  <span className="text-gray-600">{t("cityInfo.powerInformation.voltage")}</span>
                  <span className="font-medium">{powerInformation.voltage}</span>
                </li>
                <li className="flex justify-between">
                  <span className="text-gray-600">{t("cityInfo.powerInformation.frequency")}</span>
                  <span className="font-medium">{powerInformation.frequency}</span>
                </li>
                <li className="flex justify-between">
                  <span className="text-gray-600">{t("cityInfo.powerInformation.plugs")}</span>
                  <span className="font-medium">{powerInformation.plugs.join(" & ")}</span>
                </li>
              </ul>
            </div>
          )}

          {bestTimeToVisit && (
            <div className="bg-white rounded-xl shadow-lg p-6 hover:shadow-xl transition-shadow">
              <div className="flex items-center gap-3 mb-4">
                <ThermometerIcon className="w-6 h-6 text-primary-color" />
                <h2 className="text-xl font-semibold text-gray-800">{t("cityInfo.bestTimeToVisit.label")}</h2>
              </div>
              <div className="space-y-4">
                <div className="border-b border-gray-100 last:border-0 pb-3 pt-3 last:pb-0">
                  <div className="flex justify-between items-center mb-1">
                    <span className="font-medium text-primary-color">{t("cityInfo.bestTimeToVisit.peakSeason")}</span>
                    <span className="text-sm text-gray-500">{formatMonths(bestTimeToVisit.peakSeason.months)}</span>
                  </div>
                  <p className="text-gray-600 text-sm mb-2">{bestTimeToVisit.peakSeason.description}</p>
                  {/* <div className="flex gap-4 text-sm">
                    <span className="text-gray-500">🌡️ 25°C - 35°C</span>
                    <span className="text-gray-500">👥 High</span>
                  </div> */}
                </div>
                <div className="border-b border-gray-100 last:border-0 pb-3 last:pb-0">
                  <div className="flex justify-between items-center mb-1">
                    <span className="font-medium text-primary-color">{t("cityInfo.bestTimeToVisit.shoulderSeason")}</span>
                    <span className="text-sm text-gray-500">{formatMonths(bestTimeToVisit.shoulderSeason.months)}</span>
                  </div>
                  <p className="text-gray-600 text-sm mb-2">{bestTimeToVisit.shoulderSeason.description}</p>
                  {/* <div className="flex gap-4 text-sm">
                    <span className="text-gray-500">🌡️ 15°C - 25°C</span>
                    <span className="text-gray-500">👥 Moderate</span>
                  </div> */}
                </div>
                <div className="border-b border-gray-100 last:border-0 pb-3 last:pb-0">
                  <div className="flex justify-between items-center mb-1">
                    <span className="font-medium text-primary-color">{t("cityInfo.bestTimeToVisit.offSeason")}</span>
                    <span className="text-sm text-gray-500">{formatMonths(bestTimeToVisit.offSeason.months)}</span>
                  </div>
                  <p className="text-gray-600 text-sm mb-2">{bestTimeToVisit.offSeason.description}</p>
                  {/* <div className="flex gap-4 text-sm">
                    <span className="text-gray-500">🌡️ 5°C - 15°C</span>
                    <span className="text-gray-500">👥 Low</span>
                  </div> */}
                </div>
              </div>
            </div>
          )}
          {cityEvents.length > 0 && (
            // <div className="bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow">
            //   <div className="flex items-center gap-3 mb-6 px-6 pt-6">
            //     <CalendarIcon className="text-indigo-600" />
            //     <h2 className="text-xl font-semibold text-gray-800">Events & Activities</h2>
            //   </div>

            //   <div className="flex-1 w-full overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent min-h-0 px-6 max-h-64">
            //     <div className="space-y-4">
            //       {info.events.map((event) => (
            //         <div key={event.id} className="flex flex-col md:flex-row gap-6 border-b border-gray-100 pb-6 last:border-0 last:pb-0">
            //           {event.image ? (
            //             <div className="w-full md:w-20 h-20 rounded-lg overflow-hidden flex-shrink-0">
            //               <img src={event.image} alt={event.title} className="w-full h-full object-cover" />
            //             </div>
            //           ) : (
            //             <div className="w-full md:w-20 h-20 bg-gray-100 rounded-lg flex items-center justify-center flex-shrink-0">
            //               <CalendarIcon className="text-indigo-600" />
            //             </div>
            //           )}

            //           <div className="flex-grow">
            //             <h3 className="text-xl font-semibold text-gray-800 mb-2">{event.title}</h3>

            //             <div className="space-y-2 mb-4 text-xs">
            //               <div className="flex items-center text-gray-600">
            //                 <CalendarIcon className="text-gray-600 mr-2" width="16" height="16" />
            //                 <span>{moment(event.date.startDateTime).format("MMM DD, YYYY")}</span>

            //                 <ClockIcon className="text-gray-600 ml-4 mr-2" />
            //                 <span>
            //                   {moment(event.date.startDateTime).format("HH:mm")}
            //                   {event.date.endDateTime && ` - ${moment(event.date.endDateTime).format("HH:mm")}`}
            //                 </span>
            //               </div>

            //               <div className="flex items-start text-gray-600">
            //                 <MapPinIcon className="mr-2 mt-1 flex-shrink-0" />
            //                 <span className="text-sm pt-[1px]">{event.venue.name}</span>
            //               </div>

            //               {event.venue && (
            //                 <div className="flex items-center text-gray-600">
            //                   <StarIcon className="mr-2" />
            //                   <span>{event.venue.rating}</span>
            //                   <UsersIcon className="ml-4 mr-2" />
            //                   <span>{event.venue.reviews} reviews</span>
            //                 </div>
            //               )}
            //             </div>

            //             {event.description && <p className="text-gray-600 text-xs mb-4 line-clamp-2">{event.description}</p>}

            //             {event.ticketInfo && event.ticketInfo.length > 0 && (
            //               <div className="pt-2 flex gap-2">
            //                 <button className="flex-1 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors text-sm font-medium">Get Tickets</button>
            //                 <button className="px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors text-sm font-medium">More Info</button>
            //               </div>
            //             )}
            //           </div>
            //         </div>
            //       ))}
            //     </div>
            //   </div>
            // </div>
            <div className="bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow pb-6">
              <div className="flex items-center gap-3 mb-4 px-6 pt-6">
                <CalendarIcon className="text-primary-color" />
                <h2 className="text-xl font-semibold text-gray-800">Events & Activities</h2>
              </div>
              <div className="flex-1 w-full overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent min-h-0 max-h-[20.5rem] px-6">
                <EventList events={cityEvents} />
              </div>
            </div>
          )}
        </div>
      );
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  /* ********* CITY INFO ********* */
  /* */
  /* */

  return (
    <div>
      <AppNav header={CITY_INFO.HEADER?.(t("cityInfo.title"))} />
      {loadingCities ? (
        <div className={classes.loadingWrapper}>
          <PreLoading />
        </div>
      ) : (
        <div className="min-h-screen bg-gradient-to-br from-indigo-50 to-blue-50">
          <div className="container mx-auto px-4 py-12">
            {!info && (
              <>
                <div className="w-5/6 sm:w-5/6 md:w-4/6 lg:w-3/6 my-10 mx-auto">
                  <DestinationSelect
                    options={destinationOptions}
                    selectedOptionId={selectedCityId}
                    onSelectedOptionChange={handleCitySelection}
                    placeHolder={t("trips.createNewTrip.form.destination.city.placeholder")}
                  />

                  <div className="row center mt-8">
                    <Button text={t("trips.toursAndTickets.submit")} color="primary" onClick={() => handleCityInfoFetch()} />
                  </div>
                </div>
              </>
            )}

            {loading ? (
              <div className={classes.loadingWrapper}>
                <PreLoading />
              </div>
            ) : (
              <>
                {info && (
                  <>
                    {cityImgDiv} {cityInfoDetails}
                  </>
                )}
                {error && <div className="text-center">{error}</div>}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CityInfoPage;
