import React from "react";
import { CalendarIcon, MapPinIcon } from "../../icons/Icons";
import Model from "@tripian/model";

type Props = {
  title: string;
  image: string | null;
  date: {
    startDate: string;
    when: string;
  };
  address: string;
  venue: Model.CityEventVenue;
  expanded?: boolean;
  onGetTickets?: () => void;
  onMoreInfo?: () => void;
};

const EventCard: React.FC<Props> = ({ title, image, date, address, venue, expanded = false, onGetTickets, onMoreInfo }) => {
  const handleButtonClick = (e: React.MouseEvent, callback?: () => void) => {
    e.stopPropagation();
    callback?.();
  };

  return (
    <div
      className={`bg-white rounded-xl shadow-lg overflow-hidden transition-all duration-300 select-none
      ${expanded ? "flex-col" : "h-40"}`}
    >
      <div className={`relative ${expanded ? "h-40" : "h-full"}`}>
        {image ? (
          <img src={image} alt={title} className="w-full h-full object-cover" />
        ) : (
          <div className="w-full h-full bg-gray-100 rounded-lg flex items-center justify-center flex-shrink-0">{/* <CalendarIcon className="text-indigo-600 mb-12" /> */}</div>
        )}
        <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-black/0 p-4 flex flex-col justify-end">
          <div className="absolute top-3 right-3 bg-white/90 backdrop-blur-sm px-3 py-1.5 rounded-lg shadow-md">
            <span className="text-sm font-medium text-primary-color">{date.startDate}</span>
          </div>
          <h3 className="text-white font-semibold text-lg mb-1">{title}</h3>
          <div className="flex items-center gap-3 text-white/90">
            <div className="flex items-center gap-1">
              <div className="w-4 h-4 mr-1">
                <MapPinIcon className="w-4 h-4" />
              </div>

              <span className="text-sm">{venue.name}</span>
            </div>
            <div className="flex items-center gap-1">
              <span className="text-sm">⭐</span>
              <span className="pt-[1px]">{venue.rating}</span>
            </div>
          </div>
        </div>
      </div>
      {expanded && (
        <div className="p-4 space-y-3">
          <div className="flex items-center gap-2 text-gray-600">
            <div className="w-4">
              <CalendarIcon width="16" height="16" />
            </div>
            <span className="text-sm">{date.when}</span>
          </div>
          <div className="flex items-center gap-2 text-gray-600">
            <div className="w-6">
              <MapPinIcon width="16" height="16" />
            </div>

            <span className="text-sm">{address}</span>
          </div>
          <div className="pt-2 flex gap-2">
            <button onClick={(e) => handleButtonClick(e, onGetTickets)} className="flex-1 px-4 py-2 bg-primary-color text-white rounded-lg transition-colors text-sm font-medium">
              Get Tickets
            </button>
            <button
              onClick={(e) => handleButtonClick(e, onMoreInfo)}
              className="px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors text-sm font-medium"
            >
              More Info
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventCard;
